.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  /* For ADA reasons, use it */
}

.App-header {
  background-color: #111;
  font-family: Abel;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20rem;
  color: white;
}

.App-link {
  color: #61dafb;
}

.ticket {
  border: 1px solid white;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  height: 85vh;
  padding: 15px 0 0 0;
  width: calc(25vw - 2px);
}

.tickets-header {
  font-size: 5rem;
  position: fixed;
  top: 30px;
}

.tickets-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-right: auto;
  transition: all 1s ease;
}

.order-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0 15px;
  width: 95%;
}

.line-item {
  font-size: 3.5rem;
  text-align: left;
}

.modifier {
  color: red;
  text-align: left;
}

@font-face {
  font-family: "Abel";
  src: local("Abel"), url("./fonts/Abel-Regular.ttf") format("ttf");
}
